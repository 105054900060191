import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { device } from '@jam3/detect';
import classes from 'dom-classes';
import select from 'dom-select';
import { connect } from 'react-redux';
import { Footer, HamburgerMenu, PageOverlay } from '@jam3/react-ui';
import checkProps from '@jam3/react-check-extra-props';
import 'default-passive-events';
import CookieConsent from 'react-cookie-consent';
import scrollcheck from '../../util/scrollcheck.js';

import Pages from '../../components/Pages/Pages';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
// import TopInfo from '../../components/TopInfo/TopInfo';

import { setPreviousRoute, setWindowSize, setLayout, batchActions } from '../../redux/modules/app';
import { setIsMobileMenuOpen } from '../../redux/modules/main-nav';

import MainTopNav from '../../components/MainTopNav/MainTopNav';
import settings from '../../data/settings';
import mainNavData from '../../data/main-nav';
import hamburgerNavData from '../../data/hamburger-menu';
import footerData from '../../data/footer';
import lockBodyScroll from '../../util/lock-body-scroll';
import sanitizer from '../../util/sanitizer';
import layout from '../../util/layout';
import routePages from '../../routes/pages';
import backupData from '../../data/backup-data.json';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      siteData: null
    };
  }

  async componentDidMount() {
    await this.setData();
    this.setState({
      fetched: true
    });

    if (process.env.NODE_ENV !== 'production') {
      const { whyDidYouUpdate } = require('why-did-you-update');

      if (document.location.search.indexOf('performance') >= 0) {
        whyDidYouUpdate(React);
      }
    }

    if (device.isMobile) {
      window.addEventListener('scroll', this.scrollEventsMobile());
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobileMenuOpen !== this.props.isMobileMenuOpen) {
      this.props.isMobileMenuOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setPreviousRoute(prevProps.location.pathname);
    }
  }

  scrollEventsMobile = () => {
    const mainHeader = select('header.MainTopNav');
    return function() {
      let scrollY = scrollcheck('y');
      if (scrollY > 600) {
        classes.add(mainHeader, 'MainTopNav--hasScrolledMobile');
      } else if (scrollY < 600) {
        classes.remove(mainHeader, 'MainTopNav--hasScrolledMobile');
      }
    };
  };

  componentWillUnmount() {
    if (device.isMobile) {
      window.removeEventListener('scroll', this.scrollEventsMobile);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  async setData() {
    const fetchedData = {};
    const pages = `${settings.cms}pages?per_page=16`;
    await fetch(pages, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length) {
          routePages.forEach(pageName => {
            const pageData = data.filter(page => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData
          });
        } else {
          routePages.forEach(pageName => {
            const pageData = backupData.filter(page => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData
          });
        }
      })
      .catch(error => {
        routePages.forEach(pageName => {
          const pageData = backupData.filter(page => page.slug === pageName);
          if (pageData[0]) {
            fetchedData[pageData[0].slug] = pageData[0];
          }
        });
        this.setState({
          siteData: fetchedData
        });
      });
  }

  handleResize = debounce(() => {
    this.props.setLayout(window.innerWidth, window.innerHeight, layout.all);
  }, settings.resizeDebounceTime);

  render() {
    return (
      <Fragment>
        <MainTopNav
          {...mainNavData}
          showHamburger={!this.props.layout.large}
          isMobileMenuOpen={this.props.isMobileMenuOpen}
          setIsMobileMenuOpen={this.props.setIsMobileMenuOpen}
          linkComponent={PrefetchLink}
        />
        {!this.props.layout.large && (
          <Fragment>
            <PageOverlay
              isShowing={this.props.isMobileMenuOpen}
              onClick={() => this.props.setIsMobileMenuOpen(false)}
            />
            <HamburgerMenu
              {...hamburgerNavData}
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              setIsMobileMenuOpen={this.props.setIsMobileMenuOpen}
              linkComponent={PrefetchLink}
            />
          </Fragment>
        )}
        {this.state.siteData && (
          <Fragment>
            <Pages siteData={this.state.siteData} />
            <Footer {...footerData} linkComponent={PrefetchLink} />
          </Fragment>
        )}
        <CookieConsent disableStyles={true} buttonText={'Acconsento'}>
          {sanitizer("Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.")}
        </CookieConsent>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    isMobileMenuOpen: state.isMobileMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPreviousRoute: val => dispatch(setPreviousRoute(val)),
    setLayout: (width, height, layout) => dispatch(batchActions([setWindowSize({ width, height }), setLayout(layout)])),
    setIsMobileMenuOpen: val => dispatch(setIsMobileMenuOpen(val))
  };
};

App.propTypes = checkProps({
  layout: PropTypes.object.isRequired,
  setPreviousRoute: PropTypes.func.isRequired,
  setLayout: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired
});

App.defaultProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
