import routeKeys from '../routes/keys';

export default {
  copyright: '© BIONDI BISTROT',
  links: [
    {
      text: 'Ristorante',
      path: routeKeys.Ristorante
    },
    {
      text: 'Pizzeria',
      path: routeKeys.Pizzeria
    },
    {
      text: 'Bar',
      path: routeKeys.Bar
    },
    {
      text: 'Contatti',
      path: routeKeys.About
    }
  ]
};
