import routeKeys from '../routes/keys';
import logo from '../assets/images/logo.png';
import logoDark from '../assets/images/logo-dark.png';

export default {
  logoSrc: logo,
  logoDarkSrc: logoDark,
  links: [
    {
      text: 'Ristorante',
      path: routeKeys.Ristorante
    },
    {
      text: 'Pizzeria',
      path: routeKeys.Pizzeria
    },
    {
      text: 'Bar',
      path: routeKeys.Bar
    },
    {
      text: 'Contatti',
      path: routeKeys.About
    }
  ]
};
